import { Link, useNavigate } from '@remix-run/react';
import { addMonths, addYears, format } from 'date-fns';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { match } from 'ts-pattern';

import {
  type DtoChannelProgramLink,
  type DtoGetPreUnsubscribeSummaryResponse,
  EnumsOrgSubscriptionCancelOffer,
  EnumsOrgSubscriptionStatus,
  EnumsProductBillingInterval,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { useOrgAnalytics } from '../../analytics/organization';
import greenMoney from '../../assets/img/green-money.png';
import config from '../../config';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { getStaticAssetPath } from '../../utils/assets';
import { formatCurrency } from '../../utils/currency';
import { joinNames } from '../../utils/string';
import { AnalyticsEventRow } from '../Analytics/AnalyticsExperienceList';
import { InfoIcon } from '../icons/InfoIcon';
import { SlackIcon } from '../icons/SlackIcon';
import { useJoyCaptureRenderer } from '../JoyCapture';
import { HubspotMeetingsForm } from '../Marketing/HubspotMeetingsForm';
import {
  useMyOrganization,
  useMyOrganizationFeatureChecker,
  useMyOrganizer,
  useMyOrgId,
  useOrganizationSubscription,
} from '../Organization';
import { ProductUtils } from './utils';

export interface SubscriptionCancelReason {
  key: string;
  displayName: string;
}

const CANCEL_REASONS: SubscriptionCancelReason[] = [
  {
    key: 'too-expensive',
    displayName: 'Too expensive',
  },
  {
    key: 'rarely-use',
    displayName: "Didn't use it enough",
  },
  {
    key: 'other-alternative',
    displayName: 'Using another product',
  },
  {
    key: 'missing-features',
    displayName: 'Missing features',
  },
  {
    key: 'technical-issues',
    displayName: 'Too many tech issues',
  },
  {
    key: 'other',
    displayName: 'Other',
  },
] as const;

type SubscriptionCancelReasonKey = (typeof CANCEL_REASONS)[number]['key'];

const SUBSCRIPTION_CANCEL_PHASES = [
  'survey',
  'first-offer',
  'final-offer',
  'offer-accepted',
  'call-scheduled',
  'support-call',
  'confirm',
] as const;

export type SubscriptionCancelPhase =
  (typeof SUBSCRIPTION_CANCEL_PHASES)[number];

export function isSubscriptionCancelPhase(
  phase: string | undefined | null
): phase is SubscriptionCancelPhase {
  for (const validPhase of SUBSCRIPTION_CANCEL_PHASES) {
    if (validPhase === phase) {
      return true;
    }
  }
  return false;
}

function formatSubscriptionCancelPath(phase: SubscriptionCancelPhase) {
  return `/subscription/cancel/${phase}`;
}

function SubscriptionCancelLayout(props: {
  title: string;
  buttons: React.ReactNode;
  children: React.ReactNode;
}) {
  const { title, buttons, children } = props;

  const navigate = useNavigate();

  return (
    <div className='w-full p-6 text-white'>
      <div className='w-full rounded-6.25xl bg-modal px-8 py-3 flex'>
        <div className='w-1/3 flex items-center'>
          <button
            type='button'
            className='text-base font-medium text-icon-gray'
            onClick={() => navigate(-1)}
          >{`< Back`}</button>
        </div>

        <div className='w-1/3 flex justify-center items-center text-xl font-medium'>
          {title}
        </div>

        <div className='w-1/3 flex items-center justify-end'>{buttons}</div>
      </div>

      {children}
    </div>
  );
}

export function SubscriptionCancelSurvey() {
  const navigate = useNavigate();
  const organizer = useMyOrganizer();
  const organization = useMyOrganization();
  const analytics = useOrgAnalytics();

  const [selectedReasonKey, setSelectedReasonKey] =
    useState<SubscriptionCancelReasonKey | null>(null);

  const {
    call: handleSubmit,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    const reason = CANCEL_REASONS.find((r) => r.key === selectedReasonKey);
    if (!selectedReasonKey || !reason || !organization) return;

    analytics.trackSubscriptionCancelReasonSubmitted({
      reason: reason.key,
      reasonDisplayName: reason.displayName,
    });

    await apiService.organization.submitCancelReason(organization.id, {
      reason: reason.displayName,
    });

    const params = new URLSearchParams(window.location.search);
    params.set('reason', selectedReasonKey);
    const nextPath = organization.settings?.cancelOfferDisabled
      ? formatSubscriptionCancelPath('support-call')
      : formatSubscriptionCancelPath('first-offer');
    navigate({
      pathname: nextPath,
      search: params.toString(),
    });
  });

  if (!organization || !organizer) return null;
  return (
    <SubscriptionCancelLayout
      title={
        organization.settings?.cancelOfferDisabled ? `1/3 Survey` : `1/4 Survey`
      }
      buttons={
        <button
          type='button'
          className='btn-primary w-33 h-10'
          disabled={!selectedReasonKey || isRunning}
          onClick={handleSubmit}
        >
          {isRunning ? 'Submitting...' : 'Submit'}
        </button>
      }
    >
      <div className='mt-15 w-full flex flex-col items-center'>
        <p className='text-2xl font-bold'>
          We're sorry to see you go {organizer.firstName}. Why are you
          cancelling?
        </p>

        <div className='mt-8 flex flex-col gap-4'>
          {CANCEL_REASONS.map((reason) => (
            <button
              key={reason.key}
              type='button'
              className={`w-120 h-13 ${
                reason.key === selectedReasonKey
                  ? 'btn-primary'
                  : 'btn-secondary'
              }`}
              onClick={() => setSelectedReasonKey(reason.key)}
            >
              {reason.displayName}
            </button>
          ))}
        </div>
      </div>
    </SubscriptionCancelLayout>
  );
}

interface CancelOffer {
  percentageOff: number;
  duration: number;
  interval: EnumsProductBillingInterval;
}

function getFirstOffer(
  billingInterval: EnumsProductBillingInterval
): CancelOffer {
  if (
    billingInterval === EnumsProductBillingInterval.ProductBillingIntervalYearly
  ) {
    return {
      percentageOff: 50,
      duration: 1,
      interval: EnumsProductBillingInterval.ProductBillingIntervalYearly,
    };
  }
  return {
    percentageOff: 50,
    duration: 6,
    interval: EnumsProductBillingInterval.ProductBillingIntervalMonthly,
  };
}

function FirstOffer(props: {
  amount: number;
  billingInterval: EnumsProductBillingInterval;
}) {
  const { amount, billingInterval } = props;

  const offer = getFirstOffer(billingInterval);

  return (
    <div
      className='
        w-170 bg-white text-black rounded-xl
        flex
      '
    >
      <div className='flex-1 p-9 pr-0 flex flex-col justify-between items-center'>
        <div>
          <p className='text-base font-bold text-black text-center'>
            🤫 Limited Time Offer:
          </p>
          <p className='text-xl font-bold text-tertiary text-center'>
            {`${offer.percentageOff}% off for ${pluralize(
              ProductUtils.FormatInterval(offer.interval),
              offer.duration,
              true
            )}!`}
          </p>
        </div>

        <div className='flex items-center justify-center gap-8'>
          <div className='flex flex-col items-center gap-1'>
            <div className='text-sms'>Regular Price</div>
            <div
              className='text-3.5xl font-bold line-through'
              style={{
                textDecorationColor: '#FF0935',
              }}
            >
              {formatCurrency(amount)}
            </div>
          </div>
          <div className='text-5xl font-bold font-Montserrat text-green-001'>
            {formatCurrency(amount * (1 - offer.percentageOff / 100))}
          </div>
        </div>
        <div className='text-sms text-icon-gray text-center'>
          For the next{' '}
          {pluralize(
            ProductUtils.FormatInterval(offer.interval),
            offer.duration,
            true
          )}{' '}
          billed at{' '}
          {formatCurrency(amount * (1 - offer.percentageOff / 100), true)} per{' '}
          {ProductUtils.FormatInterval(billingInterval)}.
          <br />
          Cancel anytime.
        </div>
      </div>
      <img
        src={getStaticAssetPath('images/subscription/cancel/games.png')}
        alt=''
        className='flex-none w-75 h-70 object-cover rounded-r-xl'
      ></img>
    </div>
  );
}

export function SubscriptionCancelFirstOffer(props: {
  reason: SubscriptionCancelReasonKey | undefined | null;
}) {
  const navigate = useNavigate();
  const analytics = useOrgAnalytics();
  const orgId = useMyOrgId() || '';
  const { data: subscription } = useOrganizationSubscription(orgId);
  const featureChecker = useMyOrganizationFeatureChecker();

  const reason =
    CANCEL_REASONS.find((r) => r.key === props.reason) || CANCEL_REASONS[0];
  const billingInterval =
    subscription?.billingInterval ??
    EnumsProductBillingInterval.ProductBillingIntervalMonthly;
  const amount = subscription?.amount ?? 0;
  const offer = getFirstOffer(billingInterval);

  const {
    call: handleAccept,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    analytics.trackSubscriptionCancelFirstOfferAccepted();

    await apiService.organization.acceptCancelOffer(orgId, {
      offer: EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFirst,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    const params = new URLSearchParams(window.location.search);
    params.set(
      'offer',
      EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFirst
    );
    navigate({
      pathname: formatSubscriptionCancelPath('offer-accepted'),
      search: params.toString(),
    });
  });

  const handleReject = () => {
    analytics.trackSubscriptionCancelFirstOfferRejected();

    navigate({
      pathname: formatSubscriptionCancelPath('final-offer'),
      search: window.location.search,
    });
  };

  if (!subscription) return null;

  const discountDurationStr = pluralize(
    ProductUtils.FormatInterval(offer.interval),
    offer.duration,
    true
  );
  const discountPriceStr = `${formatCurrency(
    amount * (1 - offer.percentageOff / 100),
    true
  )} per ${ProductUtils.FormatInterval(billingInterval)}`;

  return (
    <SubscriptionCancelLayout
      title='2/4 Offer'
      buttons={
        <div className='flex items-center gap-2'>
          <button
            type='button'
            className='btn-secondary w-33 h-10'
            onClick={handleReject}
          >
            No Thanks
          </button>
          <button
            type='button'
            className='btn-primary w-33 h-10'
            onClick={handleAccept}
            disabled={isRunning}
          >
            {isRunning ? 'Applying...' : 'Accept Offer'}
          </button>
        </div>
      }
    >
      <div className='mt-12 w-full px-10 flex justify-between'>
        <div className='flex-1 max-w-125'>
          <p className='text-2xl font-bold'>We’d love to keep your business</p>
          <p className='mt-6 text-xl'>
            {match(reason.key)
              .with('too-expensive', () => (
                <>We understand that you feel Luna Park is too expensive.</>
              ))
              .with('rarely-use', () => (
                <>
                  It sounds like you haven’t had a chance to use Luna Park as
                  much as you had hoped.
                </>
              ))
              .with('other-alternative', () => (
                <>
                  While it’s great that you found another product that meets
                  your needs, we’re confident there are no products that do
                  everything Luna Park does.
                </>
              ))
              .with('missing-features', () => (
                <>
                  We understand there are additional features you need from Luna
                  Park and we will reach out to learn more about those features
                  and talk about our upcoming roadmap.
                </>
              ))
              .with('technical-issues', () => (
                <>
                  We’re so sorry for any tech issues you may have experienced
                  and we’ll reach out for more details so we can address these
                  issues.
                </>
              ))
              .otherwise(() => (
                <>We’d love to keep your business.</>
              ))}
            <br /> <br />
            {featureChecker.hasUnlimitedLiveBooking() ? (
              <>
                In the meantime, we can offer you {discountDurationStr} of our{' '}
                <strong>Unlimited On Demand Access Plan</strong> at 50% off.
                You’ll <u>lose</u> access to our live-hosted events but keep
                access to our library of 100s of on demand events.
              </>
            ) : (
              <>
                In the meantime, we can offer you {discountDurationStr} of
                service at {offer.percentageOff}% off or {discountPriceStr}. You
                can still cancel at any time!
              </>
            )}
            <br /> <br />
            When the offer period ends
            {subscription.currentPeriodEnd
              ? ` on ${format(
                  addMonths(
                    new Date(subscription.currentPeriodEnd),
                    ProductUtils.MonthlyInterval(offer.interval) *
                      offer.duration
                  ),
                  'MMM d, yyyy'
                )}`
              : ''}
            , your subscription will continue at {formatCurrency(amount, true)}{' '}
            per {ProductUtils.FormatInterval(billingInterval)}.
          </p>
        </div>

        <div className='flex-none'>
          <FirstOffer amount={amount} billingInterval={billingInterval} />
        </div>
      </div>
    </SubscriptionCancelLayout>
  );
}

function FinalOffer(props: {
  amount: number;
  billingInterval: EnumsProductBillingInterval;
}) {
  const { amount, billingInterval } = props;

  return (
    <div
      className='
      w-170 bg-white text-black rounded-xl
      border-2 border-red-006
      flex
    '
    >
      <div className='flex-1 p-9 pr-0 flex flex-col justify-between items-center'>
        <div>
          <p className='text-base font-bold text-black text-center'>
            🤯 SUPER EXCLUSIVE OFFER:
          </p>
          <p className='text-xl font-bold text-red-006 text-center'>
            75% off for 1 year!
          </p>
        </div>
        <div className='flex items-center justify-center gap-8'>
          <div className='flex flex-col items-center gap-1'>
            <div className='text-sms'>Regular Price</div>
            <div
              className='text-3.5xl font-bold line-through'
              style={{
                textDecorationColor: '#FF0935',
              }}
            >
              {formatCurrency(amount)}
            </div>
          </div>
          <div className='text-5xl font-bold font-Montserrat text-green-001'>
            {formatCurrency(amount * 0.25)}
          </div>
        </div>
        <div className='text-sms text-icon-gray text-center'>
          For the next 12 months billed at {formatCurrency(amount * 0.25, true)}{' '}
          per {ProductUtils.FormatInterval(billingInterval)}.
          <br />
          Cancel anytime.
        </div>
      </div>
      <img
        src={getStaticAssetPath('images/subscription/cancel/games.png')}
        alt=''
        className='flex-none w-75 h-70 object-cover rounded-r-xl'
      ></img>
    </div>
  );
}

export function SubscriptionCancelFinalOffer() {
  const navigate = useNavigate();
  const analytics = useOrgAnalytics();
  const orgId = useMyOrgId() || '';
  const { data: subscription } = useOrganizationSubscription(orgId);
  const featureChecker = useMyOrganizationFeatureChecker();

  const {
    call: handleAccept,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    analytics.trackSubscriptionCancelFinalOfferAccepted({
      phase: 'final-offer',
    });

    await apiService.organization.acceptCancelOffer(orgId, {
      offer: EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFinal,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const param = new URLSearchParams(window.location.search);
    param.set(
      'offer',
      EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFinal
    );
    navigate({
      pathname: formatSubscriptionCancelPath('offer-accepted'),
      search: param.toString(),
    });
  });

  const handleReject = () => {
    analytics.trackSubscriptionCancelFinalOfferRejected();

    navigate({
      pathname: formatSubscriptionCancelPath('support-call'),
      search: window.location.search,
    });
  };

  if (!subscription) return null;
  return (
    <SubscriptionCancelLayout
      title='2/4 Offer'
      buttons={
        <div className='flex items-center gap-2'>
          <button
            type='button'
            className='btn-secondary w-33 h-10'
            onClick={handleReject}
          >
            No Thanks
          </button>
          <button
            type='button'
            className='btn-primary w-33 h-10'
            disabled={isRunning}
            onClick={handleAccept}
          >
            {isRunning ? 'Applying...' : 'Accept Offer'}
          </button>
        </div>
      }
    >
      <div className='mt-12 w-full px-10 flex justify-between'>
        <div className='flex-1 max-w-125'>
          <p className='text-2xl font-bold'>OK - This is our final offer!</p>
          <p className='mt-6 text-xl'>
            {featureChecker.hasUnlimitedLiveBooking() ? (
              <>
                We can give you our{' '}
                <strong>Unlimited On Demand Access Plan</strong> at 75% off for
                1 year. You’ll <u>lose</u> access to our live-hosted events but
                keep access to our library of 100s of on demand events.
              </>
            ) : (
              `We can give you ${subscription.productName} at 75% off for 1 year.`
            )}
            <br /> <br />
            When the offer period ends
            {subscription.currentPeriodEnd
              ? ` on ${format(
                  addYears(new Date(subscription.currentPeriodEnd), 1),
                  'MMM d, yyyy'
                )}`
              : ''}
            , your subscription will continue at{' '}
            {formatCurrency(subscription.amount ?? 0, true)} per{' '}
            {ProductUtils.FormatInterval(
              subscription.billingInterval ??
                EnumsProductBillingInterval.ProductBillingIntervalMonthly
            )}
            .
          </p>
        </div>

        <div className='flex-none'>
          <FinalOffer
            amount={subscription?.amount ?? 0}
            billingInterval={
              subscription?.billingInterval ??
              EnumsProductBillingInterval.ProductBillingIntervalMonthly
            }
          />
        </div>
      </div>
    </SubscriptionCancelLayout>
  );
}

export function SubscriptionCancelOfferAccepted(props: {
  offer: EnumsOrgSubscriptionCancelOffer;
}) {
  const navigate = useNavigate();
  const analytics = useOrgAnalytics();
  const orgId = useMyOrgId() || '';
  const { data: subscription } = useOrganizationSubscription(orgId);

  const [showSkipButton, setShowSkipButton] = useState(false);

  useEffectOnce(() => {
    setInterval(() => {
      setShowSkipButton(true);
    }, 20 * 1000);
  });

  const handleSkip = () => {
    analytics.trackSubscriptionCancelOfferAcceptedCallSkipped({
      offer: props.offer,
    });

    navigate('/home');
  };

  const handleScheduled = () => {
    analytics.trackSubscriptionCancelOfferAcceptedCallScheduled({
      offer: props.offer,
    });

    navigate({
      pathname: formatSubscriptionCancelPath('call-scheduled'),
      search: window.location.search,
    });
  };

  if (!subscription || !subscription.currentPeriodEnd) return null;

  const { offerPercentage, endDate } = match(props.offer)
    .with(
      EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFirst,
      () => ({
        offerPercentage: '50%',
        endDate: addMonths(new Date(subscription.currentPeriodEnd ?? 0), 6),
      })
    )
    .with(
      EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFinal,
      () => ({
        offerPercentage: '75%',
        endDate: addMonths(new Date(subscription.currentPeriodEnd ?? 0), 12),
      })
    )
    .exhaustive();

  return (
    <div className='w-full p-10 flex justify-center gap-12 text-white'>
      <div className='w-90 pt-8'>
        <h2 className='text-xl font-bold text-lp-gray-001'>
          Your account has been updated!
        </h2>
        <p className='mt-5 text-base text-icon-gray'>
          Your {offerPercentage} offer will last until{' '}
          {format(endDate, 'MMM d, yyyy')}. At that point your subscription will
          continue at {formatCurrency(subscription.amount ?? 0, true)} per{' '}
          {ProductUtils.FormatInterval(
            subscription.billingInterval ??
              EnumsProductBillingInterval.ProductBillingIntervalMonthly
          )}
          .
        </p>
        <div className='mt-9 w-full bg-dark-gray rounded-1.5lg px-5 py-4'>
          <p className='text-green-001 font-bold'>
            Schedule a call so we can make sure you have everything you need to
            take full advantage of your account.
          </p>
          <ul className='mt-8 list-disc list-inside text-sms'>
            <li>A 30 minute Zoom call</li>
            <li>
              Talk about your concerns with one of our experts so we can help
              you get the most out of Luna Park.
            </li>
            <li>Feel free to bring a colleague</li>
          </ul>
        </div>

        {showSkipButton && (
          <button
            type='button'
            onClick={handleSkip}
            className='mt-5 btn text-icon-gray underline'
          >
            Skip For Now
          </button>
        )}
      </div>
      <div className='flex flex-col items-center'>
        <HubspotMeetingsForm
          title='Schedule Your Call'
          src={config.hubspot.subscriptionCancelOfferAcceptedUrl}
          onSubmitted={handleScheduled}
          className={`w-210 h-176`}
        />
      </div>
    </div>
  );
}

export function SubscriptionCancelCallScheduled() {
  return (
    <div className='w-full flex justify-center items-center pt-10 text-white'>
      <div className='w-140 bg-modal rounded-2.5xl border border-secondary px-16 py-10 gap-10 flex flex-col items-center'>
        <div className='text-tertiary font-medium text-2xl text-center'>
          Your call has been scheduled!
        </div>
        <img
          alt='calendar'
          src={getStaticAssetPath('images/calendar-3d-icon.png')}
          className='w-25 h-25'
        />
        <div className='text-center w-72'>
          <strong>
            You’ll receive an email invite with a link to the call.
          </strong>
          <br />
          In the meantime, check out our full library of team building
          experiences!
        </div>

        <Link
          to='/home'
          className='w-96 h-12.5 btn-primary flex items-center justify-center'
        >
          Browse Library
        </Link>
      </div>
    </div>
  );
}

function FinalOfferInSupportCall(props: {
  amount: number;
  billingInterval: EnumsProductBillingInterval;
  onAccept: () => Promise<void>;
}) {
  const { amount, billingInterval, onAccept } = props;

  const {
    call: handleAccept,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(onAccept);

  return (
    <div
      className='
        w-100 bg-secondary rounded-xl
        border-2 border-dashed border-icon-gray
        p-5 flex flex-col items-center
      '
    >
      <p className='text-xl font-bold font-Montserrat text-center'>
        🤯 SUPER EXCLUSIVE OFFER:
      </p>
      <p className='mt-2 text-4xl font-black font-Montserrat text-red-006 italic text-center'>
        75% off for 1 year!
      </p>

      <div className='my-5 flex justify-center items-center gap-2'>
        <p className='text-5xl font-normal font-Montserrat text-icon-gray line-through'>
          {formatCurrency(amount)}
        </p>
        <p className='text-5xl font-bold font-Montserrat'>
          {formatCurrency(amount * 0.25)}
        </p>
      </div>

      <div className='font-2xl max-w-80 tracking-wide text-center'>
        For the next 12 months billed at {formatCurrency(amount * 0.25, true)}{' '}
        per {ProductUtils.FormatInterval(billingInterval)}. Cancel anytime.
      </div>

      <button
        type='button'
        className='mt-5 w-48 h-10 btn-primary'
        disabled={isRunning}
        onClick={handleAccept}
      >
        {isRunning ? 'Applying...' : 'Get 75% Off'}
      </button>
    </div>
  );
}

export function SubscriptionCancelSupportCall() {
  const navigate = useNavigate();
  const analytics = useOrgAnalytics();
  const organization = useMyOrganization();
  const orgId = useMyOrgId() || '';
  const { data: subscription } = useOrganizationSubscription(orgId);

  const handleSkipped = () => {
    analytics.trackSubscriptionCancelSupportCallSkipped();

    navigate({
      pathname: formatSubscriptionCancelPath('confirm'),
      search: window.location.search,
    });
  };

  const handleScheduled = () => {
    analytics.trackSubscriptionCancelSupportCallScheduled();

    navigate({
      pathname: formatSubscriptionCancelPath('call-scheduled'),
      search: window.location.search,
    });
  };

  const handleAcceptFinalOffer = async () => {
    analytics.trackSubscriptionCancelFinalOfferAccepted({
      phase: 'support-call',
    });

    await apiService.organization.acceptCancelOffer(orgId, {
      offer: EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFinal,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    const params = new URLSearchParams(window.location.search);
    params.set(
      'offer',
      EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFinal
    );
    navigate({
      pathname: formatSubscriptionCancelPath('offer-accepted'),
      search: params.toString(),
    });
  };

  if (!organization || !subscription) return null;
  return (
    <SubscriptionCancelLayout
      title={
        organization.settings?.cancelOfferDisabled
          ? '2/3 Support Call'
          : '3/4 Support Call'
      }
      buttons={
        <button
          type='button'
          className='btn-secondary w-33 h-10'
          onClick={handleSkipped}
        >
          No Thanks
        </button>
      }
    >
      <div className='mt-20 w-full px-10 flex justify-between'>
        <div className='flex-1 max-w-125'>
          <p className='text-2xl font-bold'>We’re here to help</p>
          <p className='mt-6 text-base'>
            We’d love a chance to connect and make things right. You can
            schedule a time with one of our experts below who will address your
            concerns and propose a solution.
          </p>
          {!organization.settings?.cancelOfferDisabled && (
            <div className='mt-10'>
              <FinalOfferInSupportCall
                amount={subscription.amount ?? 0}
                billingInterval={
                  subscription.billingInterval ??
                  EnumsProductBillingInterval.ProductBillingIntervalMonthly
                }
                onAccept={handleAcceptFinalOffer}
              />
            </div>
          )}
        </div>

        <HubspotMeetingsForm
          title='Schedule Your Call'
          src={config.hubspot.subscriptionCancelSupportCallUrl}
          onSubmitted={handleScheduled}
          className={`w-210 h-176`}
        />
      </div>
    </SubscriptionCancelLayout>
  );
}

function HighlightStatsBox(props: {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  explanation?: string;
}) {
  const { icon, title, description, explanation } = props;

  return (
    <div
      className='relative
        w-75 h-60 rounded-2.5xl border border-lp-gray-003 bg-modal 
        pt-7.5 px-6 flex flex-col items-center
      '
    >
      {explanation && (
        <div className='absolute z-5 top-1 right-1 group cursor-pointer p-2 flex justify-center'>
          <InfoIcon className='w-3.5 h-3.5 fill-current' />
          <div
            className='
              hidden group-hover:flex
              absolute bottom-full
              w-75 bg-modal border border-secondary rounded-lg p-2
              text-sms font-medium text-center
          '
          >
            {explanation}
          </div>
        </div>
      )}
      <div className='w-15 h-15 text-6xl text-center'>{icon}</div>
      <div className='mt-5 text-3xl font-bold whitespace-nowrap text-center'>
        {title}
      </div>
      <div className='mt-2 text-xl font-medium text-center'>{description}</div>
    </div>
  );
}

function formatSocialTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const arr = [];
  if (hours > 0) {
    arr.push(`${hours.toLocaleString()} ${pluralize('hr', hours)}`);
  }
  if (minutes > 0) {
    arr.push(pluralize('min', minutes, true));
  }
  return arr.join(' ');
}

function LunaParkHighlights(props: DtoGetPreUnsubscribeSummaryResponse) {
  const highlightsStatBoxes = [];
  if (props.sessionsCount > 5) {
    highlightsStatBoxes.push(
      <HighlightStatsBox
        key={'sessionsCount'}
        icon={'🎉'}
        title={<div className='text-pink-001'>{props.sessionsCount}</div>}
        description={`Luna Park ${pluralize(
          'experience',
          props.sessionsCount
        )}`}
      />
    );
  }
  if (props.sessionsSeconds > 10 * 60 * 60) {
    highlightsStatBoxes.push(
      <HighlightStatsBox
        key={'sessionsSeconds'}
        icon={'🤟'}
        title={
          <div className=' text-tertiary'>
            {formatSocialTime(props.sessionsSeconds)}
          </div>
        }
        description='Time spent together on Luna Park'
      />
    );
  }
  if (props.savingsAmount > 50000) {
    highlightsStatBoxes.push(
      <HighlightStatsBox
        key={'savings'}
        icon={
          <img
            src={greenMoney}
            alt='money'
            className='w-full h-full object-contain'
          />
        }
        title={
          <div className=' text-green-001'>
            {formatCurrency(props.savingsAmount / 100, false)}
          </div>
        }
        description='Savings on team building'
        explanation={`This is the additional amount you would have spent on your ${props.sessionsCount} Luna Park events if you did not have a membership.`}
      />
    );
  }
  if (props.conversationsCount > 25 || props.introsCount > 10) {
    highlightsStatBoxes.push(
      <HighlightStatsBox
        key={'slack'}
        icon={<SlackIcon />}
        title={
          <div className='text-sm font-bold tracking-wider'>
            {props.conversationsCount > 25 && (
              <p>
                <span className='text-4xl font-Montserrat font-bold text-blue-005'>
                  {props.conversationsCount}
                </span>{' '}
                conversations started
              </p>
            )}
            {props.introsCount > 10 && (
              <p>
                <span className='text-4xl font-Montserrat font-bold text-blue-005'>
                  {props.introsCount}
                </span>{' '}
                groups introduced
              </p>
            )}
          </div>
        }
        description='through Slack'
      />
    );
  }

  if (highlightsStatBoxes.length === 0 && props.groupPhotos.length === 0)
    return null;

  return (
    <div className='w-full flex flex-col items-center'>
      <div className='text-3.5xl font-bold text-tertiary tracking-wide'>
        Luna Park Highlights
      </div>
      <div className='mt-4 text-xl font-medium'>
        Here’s a quick reminder of the great time your team has had on Luna Park
        so far.
      </div>
      {highlightsStatBoxes.length > 0 && (
        <div className='mt-12 flex items-center gap-4'>
          {highlightsStatBoxes}
        </div>
      )}
      {props.groupPhotos.length > 0 && (
        <div className='mt-12 flex flex-col gap-10'>
          {props.groupPhotos.map((photo) => (
            <img
              key={photo.id}
              src={photo.url}
              alt=''
              className='w-222'
              style={{
                aspectRatio: '16/9',
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function joinChannelNames(programLinks: DtoChannelProgramLink[]) {
  return joinNames(
    programLinks.map((link) => (link.channel ? `#${link.channel.name}` : ''))
  );
}

export function SubscriptionCancelConfirm(
  props: DtoGetPreUnsubscribeSummaryResponse
) {
  const { scheduledEvents, programLinks } = props;

  const navigate = useNavigate();
  const analytics = useOrgAnalytics();
  const organization = useMyOrganization();
  const orgId = useMyOrgId() || '';
  const { data: subscription } = useOrganizationSubscription(orgId);

  const renderer = useJoyCaptureRenderer();

  const {
    call: handleConfirm,
    state: {
      state: { isRunning: isConfirmingCancel },
    },
  } = useLiveAsyncCall(async () => {
    analytics.trackSubscriptionCancelConfirmed();

    await apiService.organization.cancelSubscriptionPlan(orgId);
    window.location.href = '/settings/org-settings';
  });

  const {
    call: handleAcceptFinalOffer,
    state: {
      state: { isRunning: isAcceptingFinalOffer },
    },
  } = useLiveAsyncCall(async () => {
    analytics.trackSubscriptionCancelFinalOfferAccepted({
      phase: 'confirm',
    });

    await apiService.organization.acceptCancelOffer(orgId, {
      offer: EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFinal,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    const params = new URLSearchParams(window.location.search);
    params.set(
      'offer',
      EnumsOrgSubscriptionCancelOffer.OrgSubscriptionCancelOfferFinal
    );
    navigate({
      pathname: formatSubscriptionCancelPath('offer-accepted'),
      search: params.toString(),
    });
  });

  const waterCoolerProgramLinks = programLinks.filter(
    (link) => link.programType === EnumsProgramType.ProgramTypeWaterCooler
  );
  const introsProgramLinks = programLinks.filter(
    (link) => link.programType === EnumsProgramType.ProgramTypeIntros
  );
  const celebrationsProgramLinks = programLinks.filter(
    (link) =>
      link.programType === EnumsProgramType.ProgramTypeBirthdayAndCelebrations
  );
  const totalProgramLinks =
    waterCoolerProgramLinks.length +
    introsProgramLinks.length +
    celebrationsProgramLinks.length;
  const willCancelImmediately =
    organization?.subscription.status ===
    EnumsOrgSubscriptionStatus.OrgSubscriptionStatusTrialing;

  if (!subscription || !organization) return null;
  return (
    <SubscriptionCancelLayout
      title={
        organization.settings?.cancelOfferDisabled
          ? '3/3 Confirm'
          : '4/4 Confirm'
      }
      buttons={
        <div className='flex items-center gap-2'>
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            disabled={isAcceptingFinalOffer}
            onClick={handleAcceptFinalOffer}
          >
            {isAcceptingFinalOffer ? 'Applying...' : 'Get 75% Off'}
          </button>
          <button
            type='button'
            className='btn-delete w-60 h-10'
            disabled={isConfirmingCancel}
            onClick={handleConfirm}
          >
            {isConfirmingCancel ? 'Cancelling...' : 'Confirm Cancellation'}
          </button>
        </div>
      }
    >
      <div className='mt-10 w-full px-10 flex flex-col gap-10'>
        <div>
          <div className='text-2xl font-bold'>Confirm your cancellation</div>
          <div className='mt-6 text-base'>
            Before you go, please take note of these changes that will affect
            your account
            <br />
            <br />
            <ul className='list-disc list-inside'>
              <li>
                {willCancelImmediately ? (
                  <>
                    Your plan will <strong>immediately</strong> convert to our
                    Free Plan.
                  </>
                ) : (
                  <>
                    Your plan will convert to our Free Plan on{' '}
                    <strong>
                      {format(
                        new Date(subscription.currentPeriodEnd ?? 0),
                        'MMM d, yyyy'
                      )}
                    </strong>
                  </>
                )}
              </li>
              <li>
                You’ll lose unlimited access to our library of hundreds of
                experiences
              </li>
              {totalProgramLinks > 0 && (
                <li>
                  You’ll no longer have access to your {totalProgramLinks}{' '}
                  installed slack {pluralize('program', totalProgramLinks)}:
                  <ul className='list-disc list-inside ml-8'>
                    {waterCoolerProgramLinks.length > 0 && (
                      <li>
                        Water Cooler Convos in{' '}
                        {joinChannelNames(waterCoolerProgramLinks)}
                      </li>
                    )}
                    {introsProgramLinks.length > 0 && (
                      <li>Intros in {joinChannelNames(introsProgramLinks)}</li>
                    )}
                    {celebrationsProgramLinks.length > 0 && (
                      <li>
                        Celebrations in{' '}
                        {joinChannelNames(celebrationsProgramLinks)}
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {scheduledEvents.length > 0 && (
                <li>Lastly, these scheduled events will be cancelled</li>
              )}
            </ul>
          </div>
        </div>

        {scheduledEvents.length > 0 && (
          <table
            className='table-fixed w-full border-separate'
            style={{
              borderSpacing: '0 10px',
            }}
          >
            <tbody>
              {scheduledEvents.map((event) => (
                <AnalyticsEventRow
                  key={event.id}
                  event={event}
                  className='h-27 bg-black hover:bg-dark-gray text-white text-sm cursor-pointer group'
                  renderer={renderer}
                />
              ))}
            </tbody>
          </table>
        )}

        <LunaParkHighlights {...props} />
      </div>
    </SubscriptionCancelLayout>
  );
}

export function useCancelMySubscription() {
  const navigate = useNavigate();

  return useLiveCallback(async () => {
    navigate('/subscription/cancel/survey');
  });
}
